/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDashboard = /* GraphQL */ `
  subscription OnCreateDashboard {
    onCreateDashboard {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDashboard = /* GraphQL */ `
  subscription OnUpdateDashboard {
    onUpdateDashboard {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDashboard = /* GraphQL */ `
  subscription OnDeleteDashboard {
    onDeleteDashboard {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCustomerAccountRequest = /* GraphQL */ `
  subscription OnCreateCustomerAccountRequest($owner: String) {
    onCreateCustomerAccountRequest(owner: $owner) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCustomerAccountRequest = /* GraphQL */ `
  subscription OnUpdateCustomerAccountRequest($owner: String) {
    onUpdateCustomerAccountRequest(owner: $owner) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCustomerAccountRequest = /* GraphQL */ `
  subscription OnDeleteCustomerAccountRequest($owner: String) {
    onDeleteCustomerAccountRequest(owner: $owner) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInviteHistory = /* GraphQL */ `
  subscription OnCreateInviteHistory {
    onCreateInviteHistory {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInviteHistory = /* GraphQL */ `
  subscription OnUpdateInviteHistory {
    onUpdateInviteHistory {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInviteHistory = /* GraphQL */ `
  subscription OnDeleteInviteHistory {
    onDeleteInviteHistory {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCompanyInviteHistory = /* GraphQL */ `
  subscription OnCreateCompanyInviteHistory {
    onCreateCompanyInviteHistory {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCompanyInviteHistory = /* GraphQL */ `
  subscription OnUpdateCompanyInviteHistory {
    onUpdateCompanyInviteHistory {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCompanyInviteHistory = /* GraphQL */ `
  subscription OnDeleteCompanyInviteHistory {
    onDeleteCompanyInviteHistory {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateApiKey = /* GraphQL */ `
  subscription OnCreateApiKey {
    onCreateApiKey {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateApiKey = /* GraphQL */ `
  subscription OnUpdateApiKey {
    onUpdateApiKey {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteApiKey = /* GraphQL */ `
  subscription OnDeleteApiKey {
    onDeleteApiKey {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTermsAndConditions = /* GraphQL */ `
  subscription OnCreateTermsAndConditions {
    onCreateTermsAndConditions {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTermsAndConditions = /* GraphQL */ `
  subscription OnUpdateTermsAndConditions {
    onUpdateTermsAndConditions {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTermsAndConditions = /* GraphQL */ `
  subscription OnDeleteTermsAndConditions {
    onDeleteTermsAndConditions {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePrivacyPolicy = /* GraphQL */ `
  subscription OnCreatePrivacyPolicy {
    onCreatePrivacyPolicy {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePrivacyPolicy = /* GraphQL */ `
  subscription OnUpdatePrivacyPolicy {
    onUpdatePrivacyPolicy {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePrivacyPolicy = /* GraphQL */ `
  subscription OnDeletePrivacyPolicy {
    onDeletePrivacyPolicy {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCustomerAccountTag = /* GraphQL */ `
  subscription OnCreateCustomerAccountTag {
    onCreateCustomerAccountTag {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
          __typename
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        customerAccounts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomerAccountTag = /* GraphQL */ `
  subscription OnUpdateCustomerAccountTag {
    onUpdateCustomerAccountTag {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
          __typename
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        customerAccounts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomerAccountTag = /* GraphQL */ `
  subscription OnDeleteCustomerAccountTag {
    onDeleteCustomerAccountTag {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
          __typename
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        customerAccounts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMicrosite = /* GraphQL */ `
  subscription OnCreateMicrosite {
    onCreateMicrosite {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMicrosite = /* GraphQL */ `
  subscription OnUpdateMicrosite {
    onUpdateMicrosite {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMicrosite = /* GraphQL */ `
  subscription OnDeleteMicrosite {
    onDeleteMicrosite {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTriTechProfile = /* GraphQL */ `
  subscription OnCreateTriTechProfile {
    onCreateTriTechProfile {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        items {
          id
          triTechProfileId
          payloadDescription
          payloadTransactionStatus
          action
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateTriTechProfile = /* GraphQL */ `
  subscription OnUpdateTriTechProfile {
    onUpdateTriTechProfile {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        items {
          id
          triTechProfileId
          payloadDescription
          payloadTransactionStatus
          action
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteTriTechProfile = /* GraphQL */ `
  subscription OnDeleteTriTechProfile {
    onDeleteTriTechProfile {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        items {
          id
          triTechProfileId
          payloadDescription
          payloadTransactionStatus
          action
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateTriTechProductMapping = /* GraphQL */ `
  subscription OnCreateTriTechProductMapping {
    onCreateTriTechProductMapping {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTriTechProductMapping = /* GraphQL */ `
  subscription OnUpdateTriTechProductMapping {
    onUpdateTriTechProductMapping {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTriTechProductMapping = /* GraphQL */ `
  subscription OnDeleteTriTechProductMapping {
    onDeleteTriTechProductMapping {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateShopifyProfile = /* GraphQL */ `
  subscription OnCreateShopifyProfile {
    onCreateShopifyProfile {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateShopifyProfile = /* GraphQL */ `
  subscription OnUpdateShopifyProfile {
    onUpdateShopifyProfile {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteShopifyProfile = /* GraphQL */ `
  subscription OnDeleteShopifyProfile {
    onDeleteShopifyProfile {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateShopifyProductMapping = /* GraphQL */ `
  subscription OnCreateShopifyProductMapping {
    onCreateShopifyProductMapping {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateShopifyProductMapping = /* GraphQL */ `
  subscription OnUpdateShopifyProductMapping {
    onUpdateShopifyProductMapping {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteShopifyProductMapping = /* GraphQL */ `
  subscription OnDeleteShopifyProductMapping {
    onDeleteShopifyProductMapping {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDelayStartAccounts = /* GraphQL */ `
  subscription OnCreateDelayStartAccounts {
    onCreateDelayStartAccounts {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDelayStartAccounts = /* GraphQL */ `
  subscription OnUpdateDelayStartAccounts {
    onUpdateDelayStartAccounts {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDelayStartAccounts = /* GraphQL */ `
  subscription OnDeleteDelayStartAccounts {
    onDeleteDelayStartAccounts {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAfterShipProfile = /* GraphQL */ `
  subscription OnCreateAfterShipProfile($account: String) {
    onCreateAfterShipProfile(account: $account) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAfterShipProfile = /* GraphQL */ `
  subscription OnUpdateAfterShipProfile($account: String) {
    onUpdateAfterShipProfile(account: $account) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAfterShipProfile = /* GraphQL */ `
  subscription OnDeleteAfterShipProfile($account: String) {
    onDeleteAfterShipProfile(account: $account) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
