/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput!
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdminPlan = /* GraphQL */ `
  mutation CreateAdminPlan(
    $input: CreateAdminPlanInput!
    $condition: ModelAdminPlanConditionInput
  ) {
    createAdminPlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdminPlan = /* GraphQL */ `
  mutation UpdateAdminPlan(
    $input: UpdateAdminPlanInput!
    $condition: ModelAdminPlanConditionInput
  ) {
    updateAdminPlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdminPlan = /* GraphQL */ `
  mutation DeleteAdminPlan(
    $input: DeleteAdminPlanInput!
    $condition: ModelAdminPlanConditionInput
  ) {
    deleteAdminPlan(input: $input, condition: $condition) {
      id
      name
      description
      limits
      stripePriceID
      priceValues
      listed
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCustomerAccount = /* GraphQL */ `
  mutation CreateCustomerAccount(
    $input: CreateCustomerAccountInput!
    $condition: ModelCustomerAccountConditionInput
  ) {
    createCustomerAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      url
      disabled
      users
      shopifyPlanID
      limits
      metadata
      dashboardID
      source
      createdAt
      manualBilling
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      owner
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripeSubscriptionDetails
      stripePriceID
      planID
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCustomerAccount = /* GraphQL */ `
  mutation DeleteCustomerAccount(
    $input: DeleteCustomerAccountInput!
    $condition: ModelCustomerAccountConditionInput
  ) {
    deleteCustomerAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      url
      disabled
      users
      shopifyPlanID
      limits
      metadata
      dashboardID
      source
      createdAt
      manualBilling
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      owner
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripeSubscriptionDetails
      stripePriceID
      planID
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCustomerAccount = /* GraphQL */ `
  mutation UpdateCustomerAccount(
    $input: UpdateCustomerAccountInput!
    $condition: ModelCustomerAccountConditionInput
  ) {
    updateCustomerAccount(input: $input, condition: $condition) {
      id
      company
      company_loweredcased
      url
      disabled
      users
      shopifyPlanID
      limits
      metadata
      dashboardID
      source
      createdAt
      manualBilling
      updatedAt
      plan {
        id
        name
        description
        limits
        stripePriceID
        priceValues
        listed
        order
        createdAt
        updatedAt
        __typename
      }
      owner
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripeSubscriptionDetails
      stripePriceID
      planID
      requests {
        items {
          id
          account
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCustomerAccountRequest = /* GraphQL */ `
  mutation CreateCustomerAccountRequest(
    $input: CreateCustomerAccountRequestInput!
    $condition: ModelCustomerAccountRequestConditionInput
  ) {
    createCustomerAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCustomerAccountRequest = /* GraphQL */ `
  mutation UpdateCustomerAccountRequest(
    $input: UpdateCustomerAccountRequestInput!
    $condition: ModelCustomerAccountRequestConditionInput
  ) {
    updateCustomerAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCustomerAccountRequest = /* GraphQL */ `
  mutation DeleteCustomerAccountRequest(
    $input: DeleteCustomerAccountRequestInput!
    $condition: ModelCustomerAccountRequestConditionInput
  ) {
    deleteCustomerAccountRequest(input: $input, condition: $condition) {
      id
      account
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      account
      firstname
      lastname
      email
      phone
      attributes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      account
      firstname
      lastname
      email
      phone
      attributes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      account
      firstname
      lastname
      email
      phone
      attributes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInviteHistory = /* GraphQL */ `
  mutation CreateInviteHistory(
    $input: CreateInviteHistoryInput!
    $condition: ModelInviteHistoryConditionInput
  ) {
    createInviteHistory(input: $input, condition: $condition) {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInviteHistory = /* GraphQL */ `
  mutation UpdateInviteHistory(
    $input: UpdateInviteHistoryInput!
    $condition: ModelInviteHistoryConditionInput
  ) {
    updateInviteHistory(input: $input, condition: $condition) {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInviteHistory = /* GraphQL */ `
  mutation DeleteInviteHistory(
    $input: DeleteInviteHistoryInput!
    $condition: ModelInviteHistoryConditionInput
  ) {
    deleteInviteHistory(input: $input, condition: $condition) {
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyInviteHistory = /* GraphQL */ `
  mutation CreateCompanyInviteHistory(
    $input: CreateCompanyInviteHistoryInput!
    $condition: ModelCompanyInviteHistoryConditionInput
  ) {
    createCompanyInviteHistory(input: $input, condition: $condition) {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyInviteHistory = /* GraphQL */ `
  mutation UpdateCompanyInviteHistory(
    $input: UpdateCompanyInviteHistoryInput!
    $condition: ModelCompanyInviteHistoryConditionInput
  ) {
    updateCompanyInviteHistory(input: $input, condition: $condition) {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyInviteHistory = /* GraphQL */ `
  mutation DeleteCompanyInviteHistory(
    $input: DeleteCompanyInviteHistoryInput!
    $condition: ModelCompanyInviteHistoryConditionInput
  ) {
    deleteCompanyInviteHistory(input: $input, condition: $condition) {
      id
      email
      account
      invitedby
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $input: CreateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    createApiKey(input: $input, condition: $condition) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $input: UpdateApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    updateApiKey(input: $input, condition: $condition) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey(
    $input: DeleteApiKeyInput!
    $condition: ModelApiKeyConditionInput
  ) {
    deleteApiKey(input: $input, condition: $condition) {
      id
      account
      key
      keyid
      disabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTermsAndConditions = /* GraphQL */ `
  mutation CreateTermsAndConditions(
    $input: CreateTermsAndConditionsInput!
    $condition: ModelTermsAndConditionsConditionInput
  ) {
    createTermsAndConditions(input: $input, condition: $condition) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTermsAndConditions = /* GraphQL */ `
  mutation UpdateTermsAndConditions(
    $input: UpdateTermsAndConditionsInput!
    $condition: ModelTermsAndConditionsConditionInput
  ) {
    updateTermsAndConditions(input: $input, condition: $condition) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTermsAndConditions = /* GraphQL */ `
  mutation DeleteTermsAndConditions(
    $input: DeleteTermsAndConditionsInput!
    $condition: ModelTermsAndConditionsConditionInput
  ) {
    deleteTermsAndConditions(input: $input, condition: $condition) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPrivacyPolicy = /* GraphQL */ `
  mutation CreatePrivacyPolicy(
    $input: CreatePrivacyPolicyInput!
    $condition: ModelPrivacyPolicyConditionInput
  ) {
    createPrivacyPolicy(input: $input, condition: $condition) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePrivacyPolicy = /* GraphQL */ `
  mutation UpdatePrivacyPolicy(
    $input: UpdatePrivacyPolicyInput!
    $condition: ModelPrivacyPolicyConditionInput
  ) {
    updatePrivacyPolicy(input: $input, condition: $condition) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePrivacyPolicy = /* GraphQL */ `
  mutation DeletePrivacyPolicy(
    $input: DeletePrivacyPolicyInput!
    $condition: ModelPrivacyPolicyConditionInput
  ) {
    deletePrivacyPolicy(input: $input, condition: $condition) {
      id
      url
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      metadata
      createdAt
      updatedAt
      customerAccounts {
        items {
          id
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCustomerAccountTag = /* GraphQL */ `
  mutation CreateCustomerAccountTag(
    $input: CreateCustomerAccountTagInput!
    $condition: ModelCustomerAccountTagConditionInput
  ) {
    createCustomerAccountTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
          __typename
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        customerAccounts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateCustomerAccountTag = /* GraphQL */ `
  mutation UpdateCustomerAccountTag(
    $input: UpdateCustomerAccountTagInput!
    $condition: ModelCustomerAccountTagConditionInput
  ) {
    updateCustomerAccountTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
          __typename
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        customerAccounts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteCustomerAccountTag = /* GraphQL */ `
  mutation DeleteCustomerAccountTag(
    $input: DeleteCustomerAccountTagInput!
    $condition: ModelCustomerAccountTagConditionInput
  ) {
    deleteCustomerAccountTag(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customerAccount {
        id
        company
        company_loweredcased
        url
        disabled
        users
        shopifyPlanID
        limits
        metadata
        dashboardID
        source
        createdAt
        manualBilling
        updatedAt
        plan {
          id
          name
          description
          limits
          stripePriceID
          priceValues
          listed
          order
          createdAt
          updatedAt
          __typename
        }
        owner
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripeSubscriptionDetails
        stripePriceID
        planID
        requests {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        __typename
      }
      tag {
        id
        name
        metadata
        createdAt
        updatedAt
        customerAccounts {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createMicrosite = /* GraphQL */ `
  mutation CreateMicrosite(
    $input: CreateMicrositeInput!
    $condition: ModelMicrositeConditionInput
  ) {
    createMicrosite(input: $input, condition: $condition) {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMicrosite = /* GraphQL */ `
  mutation UpdateMicrosite(
    $input: UpdateMicrositeInput!
    $condition: ModelMicrositeConditionInput
  ) {
    updateMicrosite(input: $input, condition: $condition) {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMicrosite = /* GraphQL */ `
  mutation DeleteMicrosite(
    $input: DeleteMicrositeInput!
    $condition: ModelMicrositeConditionInput
  ) {
    deleteMicrosite(input: $input, condition: $condition) {
      id
      account
      disabled
      templateName
      micrositeFormElements
      micrositeStyle
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTriTechProfile = /* GraphQL */ `
  mutation CreateTriTechProfile(
    $input: CreateTriTechProfileInput!
    $condition: ModelTriTechProfileConditionInput
  ) {
    createTriTechProfile(input: $input, condition: $condition) {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        items {
          id
          triTechProfileId
          payloadDescription
          payloadTransactionStatus
          action
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateTriTechProfile = /* GraphQL */ `
  mutation UpdateTriTechProfile(
    $input: UpdateTriTechProfileInput!
    $condition: ModelTriTechProfileConditionInput
  ) {
    updateTriTechProfile(input: $input, condition: $condition) {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        items {
          id
          triTechProfileId
          payloadDescription
          payloadTransactionStatus
          action
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteTriTechProfile = /* GraphQL */ `
  mutation DeleteTriTechProfile(
    $input: DeleteTriTechProfileInput!
    $condition: ModelTriTechProfileConditionInput
  ) {
    deleteTriTechProfile(input: $input, condition: $condition) {
      id
      account
      enabled
      product_data
      secret_key
      createdAt
      updatedAt
      tritech_contract_action_mapper {
        items {
          id
          triTechProfileId
          payloadDescription
          payloadTransactionStatus
          action
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTriTechContractActionMapper = /* GraphQL */ `
  mutation CreateTriTechContractActionMapper(
    $input: CreateTriTechContractActionMapperInput!
    $condition: ModelTriTechContractActionMapperConditionInput
  ) {
    createTriTechContractActionMapper(input: $input, condition: $condition) {
      id
      triTechProfileId
      payloadDescription
      payloadTransactionStatus
      action
      createdAt
      updatedAt
      triTechProfile {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        tritech_contract_action_mapper {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateTriTechContractActionMapper = /* GraphQL */ `
  mutation UpdateTriTechContractActionMapper(
    $input: UpdateTriTechContractActionMapperInput!
    $condition: ModelTriTechContractActionMapperConditionInput
  ) {
    updateTriTechContractActionMapper(input: $input, condition: $condition) {
      id
      triTechProfileId
      payloadDescription
      payloadTransactionStatus
      action
      createdAt
      updatedAt
      triTechProfile {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        tritech_contract_action_mapper {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteTriTechContractActionMapper = /* GraphQL */ `
  mutation DeleteTriTechContractActionMapper(
    $input: DeleteTriTechContractActionMapperInput!
    $condition: ModelTriTechContractActionMapperConditionInput
  ) {
    deleteTriTechContractActionMapper(input: $input, condition: $condition) {
      id
      triTechProfileId
      payloadDescription
      payloadTransactionStatus
      action
      createdAt
      updatedAt
      triTechProfile {
        id
        account
        enabled
        product_data
        secret_key
        createdAt
        updatedAt
        tritech_contract_action_mapper {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createTriTechProductMapping = /* GraphQL */ `
  mutation CreateTriTechProductMapping(
    $input: CreateTriTechProductMappingInput!
    $condition: ModelTriTechProductMappingConditionInput
  ) {
    createTriTechProductMapping(input: $input, condition: $condition) {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTriTechProductMapping = /* GraphQL */ `
  mutation UpdateTriTechProductMapping(
    $input: UpdateTriTechProductMappingInput!
    $condition: ModelTriTechProductMappingConditionInput
  ) {
    updateTriTechProductMapping(input: $input, condition: $condition) {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTriTechProductMapping = /* GraphQL */ `
  mutation DeleteTriTechProductMapping(
    $input: DeleteTriTechProductMappingInput!
    $condition: ModelTriTechProductMappingConditionInput
  ) {
    deleteTriTechProductMapping(input: $input, condition: $condition) {
      id
      account
      blustream_product_id
      tritech_product_categories
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShopifyProfile = /* GraphQL */ `
  mutation CreateShopifyProfile(
    $input: CreateShopifyProfileInput!
    $condition: ModelShopifyProfileConditionInput
  ) {
    createShopifyProfile(input: $input, condition: $condition) {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShopifyProfile = /* GraphQL */ `
  mutation UpdateShopifyProfile(
    $input: UpdateShopifyProfileInput!
    $condition: ModelShopifyProfileConditionInput
  ) {
    updateShopifyProfile(input: $input, condition: $condition) {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShopifyProfile = /* GraphQL */ `
  mutation DeleteShopifyProfile(
    $input: DeleteShopifyProfileInput!
    $condition: ModelShopifyProfileConditionInput
  ) {
    deleteShopifyProfile(input: $input, condition: $condition) {
      id
      account
      store_name
      access_token
      refresh_token
      enabled
      concent
      concentTitle
      showSteper
      isProfileLock
      showWelcomePopUp
      shopifyStoreDomain
      shopifyStoreId
      plan_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShopifyProductMapping = /* GraphQL */ `
  mutation CreateShopifyProductMapping(
    $input: CreateShopifyProductMappingInput!
    $condition: ModelShopifyProductMappingConditionInput
  ) {
    createShopifyProductMapping(input: $input, condition: $condition) {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShopifyProductMapping = /* GraphQL */ `
  mutation UpdateShopifyProductMapping(
    $input: UpdateShopifyProductMappingInput!
    $condition: ModelShopifyProductMappingConditionInput
  ) {
    updateShopifyProductMapping(input: $input, condition: $condition) {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShopifyProductMapping = /* GraphQL */ `
  mutation DeleteShopifyProductMapping(
    $input: DeleteShopifyProductMappingInput!
    $condition: ModelShopifyProductMappingConditionInput
  ) {
    deleteShopifyProductMapping(input: $input, condition: $condition) {
      id
      account
      blustream_product_id
      shopify_product_ids
      shopify_product_variants
      shopify_product_details
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDelayStartAccounts = /* GraphQL */ `
  mutation CreateDelayStartAccounts(
    $input: CreateDelayStartAccountsInput!
    $condition: ModelDelayStartAccountsConditionInput
  ) {
    createDelayStartAccounts(input: $input, condition: $condition) {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDelayStartAccounts = /* GraphQL */ `
  mutation UpdateDelayStartAccounts(
    $input: UpdateDelayStartAccountsInput!
    $condition: ModelDelayStartAccountsConditionInput
  ) {
    updateDelayStartAccounts(input: $input, condition: $condition) {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDelayStartAccounts = /* GraphQL */ `
  mutation DeleteDelayStartAccounts(
    $input: DeleteDelayStartAccountsInput!
    $condition: ModelDelayStartAccountsConditionInput
  ) {
    deleteDelayStartAccounts(input: $input, condition: $condition) {
      id
      account
      expirationUnixTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAfterShipProfile = /* GraphQL */ `
  mutation CreateAfterShipProfile(
    $input: CreateAfterShipProfileInput!
    $condition: ModelAfterShipProfileConditionInput
  ) {
    createAfterShipProfile(input: $input, condition: $condition) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAfterShipProfile = /* GraphQL */ `
  mutation UpdateAfterShipProfile(
    $input: UpdateAfterShipProfileInput!
    $condition: ModelAfterShipProfileConditionInput
  ) {
    updateAfterShipProfile(input: $input, condition: $condition) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAfterShipProfile = /* GraphQL */ `
  mutation DeleteAfterShipProfile(
    $input: DeleteAfterShipProfileInput!
    $condition: ModelAfterShipProfileConditionInput
  ) {
    deleteAfterShipProfile(input: $input, condition: $condition) {
      id
      account
      webhook_secret
      createdAt
      updatedAt
      __typename
    }
  }
`;
